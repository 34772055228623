import React, { useState } from 'react'
import { graphql, Link } from 'gatsby'
// import Img from 'gatsby-image'
import Pagina from '../components/Pagina'
// CSS, SCSS
import 'swiper/css/swiper.css'
import './styles/solicite-sua-coleta.scss'

// Placeholder
import placeholdermapa from '../images/solicite-sua-coleta/1048x260.png'

// Assets
// import setabaixar from '../images/solicite-sua-coleta/001-down-arrow.svg'
import pin from '../images/solicite-sua-coleta/003-pin.svg'
import iconePrinter from '../images/solicite-sua-coleta/002-printer.svg'
import setabaixarBranco from '../images/solicite-sua-coleta/001-down-arrow-br.svg'
import iconeUsuario from '../images/solicite-sua-coleta/Group-green.svg'
import iconeCaminhao from '../images/solicite-sua-coleta/tank-truck.svg'
import iconeLogout from '../images/solicite-sua-coleta/002-logout-green.svg'

const Molde = props => (
  <>
    <tr>
      <td>
        <div className="w-100 rowpdfs">
          <div className="row justify-content-betwween pt-2 pb-2 pr-lg-4 pl-lg-4 pr-2 pl-2">
            <div className="col-lg-7 col-6 d-flex align-items-center">
              {props.titulo}
            </div>
            <div className="col-lg-5 col-6 d-flex justify-content-end">
              <button className="btn btn-buttonGreen3 w-50 text-white font-Spinnaker"><div className="d-flex justify-content-lg-between justify-content-center"><span className="text-white d-lg-flex d-none align-items-center">Imprimir</span> <img src={iconePrinter} alt="Impressora" /></div></button>
              <button className="btn btn-buttonGreen3 w-50 text-white font-Spinnaker ml-3"><div className="d-flex justify-content-lg-between justify-content-center"><span className="text-white d-lg-flex d-none align-items-center">Baixar</span> <img src={setabaixarBranco} alt="Seta baixar em Branco" /></div></button>
            </div>
          </div>
        </div>
      </td>
    </tr>
  </>
)

const HistoricoMolde = props => (
  <>
    <tr>
      <td className="pl-2 pt-3 pb-3 pr-4">{props.id}</td>
      <td className="pl-2">{props.data}</td>
      <td className="pl-2">{props.cnpj}</td>
      <td className="pl-2">{props.responsavel}</td>
      <td className="pl-2">{props.litros}</td>
    </tr>
  </>
)

const SoliciteSuaColeta = ({ data }) => {
  const [dados, setDados] = useState(false)
  const [historico, setHistorico] = useState(false)
  const [pesquisa, setPesquisa] = useState(false)
  return (
    <>
      <Pagina pagina={data.pagina} />
      <div className="bg-solicite">
        <div className="container containerMobile">
          <div className="row">
            <div className="col-lg-5 p-0 bg-leftblue">
              <div className="container">
                <div className="col-lg-10 justify-content-end d-lg-none d-flex">
                  <p className="position-absolute pr-4 pt-3 text-white above">Sair&nbsp;</p>
                  <img src={iconeLogout} alt="" className="position-absolute pt-3" />
                </div>
                <div className="row pt-5 pb-5 pl-lg-4 pl-4 pr-lg-0 pr-4">
                  <div className="col-lg-2">
                    <div className="d-flex justify-content-lg-end justify-content-center">
                      <img src={iconeUsuario} alt="imagem do usuario" />
                    </div>
                  </div>
                  <div className="col-lg-8 pr-lg-2 pl-lg-2 pr-0 pl-0">
                    <h2 className="text-white text-lg-left text-center">José da Silva</h2>
                    <p className="text-white m-0 line-height-normal text-lg-left text-center font-size-125">CNPJ 08.156.357/0001-34</p>
                    <p className="text-white m-0 line-height-normal text-lg-left text-center font-size-125">Mogi das Cruzes - SP</p>
                    <Link to="#dados"><button className="btn btn-buttonGreen w-100 mt-3 font-Spinnaker" onClick={() => {
                      setDados(true)
                      setHistorico(false)
                      setPesquisa(false)
                    }}>Dados Cadastrais</button></Link>
                    <Link to="#historico"><button className="btn btn-buttonGreen w-100 mt-3 font-Spinnaker" onClick={() => {
                      setHistorico(true)
                      setDados(false)
                      setPesquisa(false)
                    }}>Histórico de Solicitações</button></Link>
                    <Link to="#pesquisa"><button className="btn btn-buttonGreen3 w-100 mt-3 mb-lg-0 mb-3 font-Spinnaker" onClick={() => {
                      setPesquisa(true)
                      setDados(false)
                      setHistorico(false)
                    }}>Responda a Nossa<br/> Pesquisa de Satisfação</button></Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-7 p-0 bg-rightblue">
              <div className="container">
                <div className="col-lg-12 justify-content-end d-lg-flex d-none pr-5">
                  <p className="position-absolute pt-3 text-white above pr-4">Sair&nbsp;</p>
                  <img src={iconeLogout} alt="" className="position-absolute pt-3" />
                </div>
                <div className="row">
                  <div className="col-lg-12 p-lg-5 pt-5 pb-5 pr-4 pl-4">
                    <h4 className="text-white pb-2 border-bottom-coleta">Solicite aqui sua coleta</h4>
                    <form className="form-coleta p-0 w-100 mw-100">
                      <div className="row pt-2">
                        <div className="col-lg-5">
                          <label className="text-white font-Spinnaker">Estado</label>
                          <input type="text" className="w-100 mb-3"/>
                        </div>
                        <div className="col-lg-7">
                          <label className="text-white font-Spinnaker">Cidade</label>
                          <input type="text" className="w-100 mb-3"/>
                        </div>
                        <div className="col-lg-7">
                          <label className="text-white font-Spinnaker">CNPJ da Unidade a ser Coletada</label>
                          <input type="text" className="w-100 mb-3"/>
                        </div>
                        <div className="col-lg-5">
                          <label className="text-white font-Spinnaker">Telefone</label>
                          <input type="text" className="w-100 mb-3"/>
                        </div>
                        <div className="col-lg-12">
                          <label className="text-white font-Spinnaker">Nome do Responsável</label>
                          <input type="text" className="w-100 mb-3"/>
                        </div>
                        <div className="col-lg-7">
                          <label className="text-white font-Spinnaker">E-mail</label>
                          <input type="text" className="w-100 mb-3"/>
                        </div>
                        <div className="col-lg-5">
                          <label className="text-white font-Spinnaker">Quantidade de Litros</label>
                          <input type="text" className="w-100 mb-3"/>
                        </div>
                        <div className="col-lg-5">
                          <button className="text-white btn-entrar btn w-100 py-2 mb-3 font-Spinnaker">Confirmar</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="gradient-blue-to-green"></div>
      {dados
        ? <div className="container pr-lg-5 pl-lg-5 pt-5 pb-2 pr-4 pl-4" id="dados">
          <div className="row justify-content-between align-items-end">
            <div className="col-8">
              <h4 className="text-lwart-blue pb-2 mb-0 d-flex align-items-center"><b className="font-Spinnaker">Dados Cadastrais</b></h4>
            </div>
            <div className="col-4 justify-content-end d-flex">
              <img src={iconeCaminhao} alt="icone caminhao" className="pb-2"/>
            </div>
          </div>
          <div className="col-12 border-bottom-coleta mb-2" />
          <div className="row">
            <div className="col-lg-2"><input type="text" placeholder="Estado" className="w-100 mb-3 inputDados"/></div>
            <div className="col-lg-4"><input type="text" placeholder="Cidade" className="w-100 mb-3 inputDados"/></div>
            <div className="col-lg-4"><input type="text" placeholder="CNPJ da Unidade a ser Coletada" className="w-100 mb-3 inputDados"/></div>
            <div className="col-lg-2"><input type="text" placeholder="Telefone" className="w-100 mb-3 inputDados"/></div>
            <div className="col-lg-5"><input type="text" placeholder="Nome" className="w-100 mb-3 inputDados"/></div>
            <div className="col-lg-5"><input type="text" placeholder="Nome" className="w-100 mb-3 inputDados"/></div>
            <div className="col-lg-2"><button className="btn bg-lwart-blue text-white font-Spinnaker w-100 h-75">Confirmar</button></div>
          </div>
        </div> : ''}
      {historico
        ? <div className="container pr-lg-5 pl-lg-5 pt-5 pb-2 pr-4 pl-4" id="historico">
          <div className="row justify-content-between align-items-end">
            <div className="col-8">
              <h4 className="text-lwart-blue pb-2 mb-0 d-flex align-items-center"><b className="font-Spinnaker">Histórico de Solicitações</b></h4>
            </div>
            <div className="col-4 justify-content-end d-flex">
              <img src={iconeCaminhao} alt="icone caminhao" className="pb-2"/>
            </div>
          </div>
          <div className="col-12 border-bottom-coleta" />
          <table className="tablehistorico w-100 table col-12 p-4">
            <thead>
              <tr className="bg-lwart-blue text-white">
                <th scope="col" className="p-2">ID</th>
                <th scope="col" className="p-2">Data</th>
                <th scope="col" className="p-2">CNPJ</th>
                <th scope="col" className="p-2">Responsável</th>
                <th scope="col" className="p-2">Litros</th>
              </tr>
            </thead>
            <tbody>
              <HistoricoMolde id="12786" data="02.09.2020" cnpj="08.103.168/0001-34" responsavel="Thomas Shelby" litros="1000" />
              <HistoricoMolde id="12786" data="02.09.2020" cnpj="08.103.168/0001-34" responsavel="Thomas Shelby" litros="1000" />
              <HistoricoMolde id="12786" data="02.09.2020" cnpj="08.103.168/0001-34" responsavel="Thomas Shelby" litros="1000" />
              <HistoricoMolde id="12786" data="02.09.2020" cnpj="08.103.168/0001-34" responsavel="Thomas Shelby" litros="1000" />
              <HistoricoMolde id="12786" data="02.09.2020" cnpj="08.103.168/0001-34" responsavel="Thomas Shelby" litros="1000" />
              <HistoricoMolde id="12786" data="02.09.2020" cnpj="08.103.168/0001-34" responsavel="Thomas Shelby" litros="1000" />
            </tbody>
          </table>
        </div> : ''}
      {pesquisa
        ? <div className="container pr-lg-5 pl-lg-5 pt-5 pb-5 pr-4 pl-4" id="pesquisa">
          <div className="row justify-content-between align-items-end">
            <div className="col-8">
              <h4 className="text-lwart-blue pb-2 mb-0 d-flex align-items-center"><b className="font-Spinnaker">Pesquisa de Satisfação</b></h4>
            </div>
            <div className="col-4 justify-content-end d-flex">
              <img src={iconeCaminhao} alt="icone caminhao" className="pb-2"/>
            </div>
          </div>
          <div className="col-12 border-bottom-coleta mb-2" />

          <form className="formPesquisa">
            <label>1) A frequência da coleta está adequada?</label>
            <div className="row row-cols-lg row-cols-1 mb-5 align-items-center">
              <div className="col-lg-auto">
                <input type="radio" id="Sim" name="question1" value="Sim"/><label htmlFor="Sim"> Sim </label>
              </div>
              <div className="col-lg-auto">
                <input type="radio" id="Nao" name="question1" value="Nao"/><label htmlFor="Nao"> Não </label>
              </div>
            </div>

            <label>2) Quem é o responsável pela destinação do Óleo Lubrificante Usado em seu estabelecimento?</label>
            <div className="row row-cols-lg row-cols-1 mb-5 align-items-center">
              <div className="col-lg-auto">
                <input type="radio" id="Proprietario" name="question2" value="Proprietario"/><label htmlFor="Proprietario"> Proprietário </label>
              </div>
              <div className="col-lg-auto">
                <input type="radio" id="Gerente" name="question2" value="Gerente"/><label htmlFor="Gerente"> Gerente </label>
              </div>
              <div className="col-lg-auto">
                <input type="radio" id="Frentista" name="question2" value="Frentista"/><label htmlFor="Frentista"> Frentista </label>
              </div>
              <div className="col-lg-auto">
                <input type="radio" id="Responsavel_pela_troca_de_oleo" name="question2" value="Responsavel_pela_troca_de_oleo"/><label htmlFor="Responsavel_pela_troca_de_oleo"> Responsável pela troca de Óleo </label>
              </div>
              <div className="col-lg-12 d-flex align-items-center mt-lg-3">
                <input type="radio" id="Outro" name="question2" value="Outro"/><label htmlFor="Outro text-nowrap" className="mb-0"> Outro </label><input type="text" className="inputPesquisa w-100" />
              </div>
            </div>

            <label>3) Qual a forma de contato você utiliza atualmente para solicitar a coleta com a Lwart?</label>
            <div className="row row-cols-lg row-cols-1 mb-5 align-items-center">
              <div className="col-lg-auto">
                <input type="radio" id="Disque_coleta" name="question3" value="Disque_coleta"/><label htmlFor="Disque_coleta"> Disque Coleta (0800) </label>
              </div>
              <div className="col-lg-auto">
                <input type="radio" id="Celular_coletor" name="question3" value="Celular_coletor"/><label htmlFor="Celular_coletor"> Celular do coletor </label>
              </div>
              <div className="col-lg-auto">
                <input type="radio" id="Ligacao" name="question3" value="Ligacao"/><label htmlFor="Ligacao"> Ligação direta para o centro de coleta da sua região </label>
              </div>
              <div className="col-lg-auto">
                <input type="radio" id="Site" name="question3" value="Site"/><label htmlFor="Site"> Site/Fale conosco </label>
              </div>
              <div className="col-lg-12 mt-lg-3 d-flex align-items-center">
                <label htmlFor="Melhor_forma" className="text-nowrap mb-0"> Qual é a melhor forma para você? </label> <input type="text" className="inputPesquisa" />
              </div>
            </div>

            <label>4) De acordo com sua resposta anterior, como você avaliar o atendimento da Lwart em suas solicitações de coleta</label>
            <div className="row row-cols-lg row-cols-1 mb-5 align-items-center">
              <div className="col-lg-auto">
                <input type="radio" id="Otimo4" name="question4" value="Otimo"/><label htmlFor="Otimo4"> Ótimo </label>
              </div>
              <div className="col-lg-auto">
                <input type="radio" id="Bom4" name="question4" value="Bom"/><label htmlFor="Bom4"> Bom </label>
              </div>
              <div className="col-lg-auto">
                <input type="radio" id="Regular4" name="question4" value="Regular"/><label htmlFor="Regular4"> Regular </label>
              </div>
              <div className="col-lg-auto">
                <input type="radio" id="Ruim4" name="question4" value="Ruim"/><label htmlFor="Ruim4"> Ruim </label>
              </div>
              <div className="col-lg-12 mt-lg-3 d-flex align-items-center">
                <label htmlFor="Por_que4" className="text-nowrap mb-0"> Por quê? </label><input type="text" className="inputPesquisa" />
              </div>
            </div>

            <label>5) Sobre o atendimento do coletor</label>
            <div className="row row-cols-lg row-cols-1 mb-5 align-items-center">
              <div className="col-lg-auto">
                <input type="radio" id="Otimo5" name="question5" value="Otimo"/><label htmlFor="Otimo5"> Ótimo </label>
              </div>
              <div className="col-lg-auto">
                <input type="radio" id="Bom5" name="question5" value="Bom"/><label htmlFor="Bom5"> Bom </label>
              </div>
              <div className="col-lg-auto">
                <input type="radio" id="Regular5" name="question5" value="Regular"/><label htmlFor="Regular5"> Regular </label>
              </div>
              <div className="col-lg-auto">
                <input type="radio" id="Ruim5" name="question5" value="Ruim"/><label htmlFor="Ruim5"> Ruim </label>
              </div>
              <div className="col-lg-12 mt-lg-3 d-flex align-items-center">
                <label htmlFor="Por_que5" className="text-nowrap mb-0"> Por quê? </label><input type="text" className="inputPesquisa" />
              </div>
            </div>

            <label>6) O colaborador da Lwart entrega a documentação necessária (CCO)?</label>
            <div className="row row-cols-lg row-cols-1 mb-5 align-items-center">
              <div className="col-lg-auto">
                <input type="radio" id="Sempre" name="question6" value="Sempre"/><label htmlFor="Sempre"> Sempre </label>
              </div>
              <div className="col-lg-auto">
                <input type="radio" id="As_vezes" name="question6" value="As_vezes"/><label htmlFor="As_vezes"> Às vezes </label>
              </div>
              <div className="col-lg-auto">
                <input type="radio" id="Raramente" name="question6" value="Raramente"/><label htmlFor="Raramente"> Raramente </label>
              </div>
              <div className="col-lg-auto">
                <input type="radio" id="Nunca" name="question6" value="Nunca"/><label htmlFor="Nunca"> Nunca </label>
              </div>
              <div className="col-lg-auto">
                <input type="radio" id="Nao_sei_informar" name="question6" value="Nao_sei_informar"/><label htmlFor="Nao_sei_informar"> Não sei informar </label>
              </div>
            </div>

            <label>7) Como você tomou conhecimento sobre os serviços da Lwart?</label>
            <div className="row row-cols-lg row-cols-1 mb-5 align-items-center">
              <div className="col-lg-auto">
                <input type="radio" id="Indicacao_amigos" name="question7" value="Indicacao_amigos"/><label htmlFor="Indicacao_amigos"> Indicação de amigos </label>
              </div>
              <div className="col-lg-auto">
                <input type="radio" id="Site_Redes" name="question7" value="Site_Redes"/><label htmlFor="Site_Redes"> Site/redes sociais </label>
              </div>
              <div className="col-lg-auto">
                <input type="radio" id="Anuncios" name="question7" value="Anuncios"/><label htmlFor="Anuncios"> Anúncios </label>
              </div>
              <div className="col-lg-auto">
                <input type="radio" id="Caminhoes" name="question7" value="Caminhoes"/><label htmlFor="Caminhoes"> Caminhões </label>
              </div>
              <div className="col-lg-12 mt-lg-3 d-flex align-items-center">
                <input type="radio" id="Outros" name="question7" value="Outros"/><label htmlFor="Outros" className="text-nowrap mb-0"> Outros </label><input type="text" className="inputPesquisa" />
              </div>
            </div>

            <label>8) O que você considera mais importante no momento da escolha da empesa que destinará o óleo usado?</label>
            <div className="row row-cols-lg row-cols-1 mb-5 align-items-center">
              <div className="col-lg-auto">
                <input type="radio" id="Garantia_legislacao" name="question8" value="Garantia_legislacao"/><label htmlFor="Garantia_legislacao"> Garantia de que a legislação está sendo atendida </label>
              </div>
              <div className="col-lg-auto">
                <input type="radio" id="Atendimento" name="question8" value="Atendimento"/><label htmlFor="Atendimento"> Atendimento </label>
              </div>
              <div className="col-lg-auto">
                <input type="radio" id="Condicoes_comerciais" name="question8" value="Condicoes_comerciais"/><label htmlFor="Condicoes_comerciais"> Condições Comerciais </label>
              </div>
            </div>

            <label>9) Qual a chance de você indicar a Lwart para um amigo?</label>
            <div className="d-lg-flex d-none justify-content-between">
              <p>Nada provável</p>
              <p>Muito provável</p>
            </div>
            <div className="row row-cols-lg-11 row-cols-6 mb-5 align-items-center w-100">
              <div className="col-lg">
                <input type="radio" id="0" name="question9" value="0"/><label htmlFor="0"> 0 </label>
              </div>
              <div className="col-lg">
                <input type="radio" id="1" name="question9" value="1"/><label htmlFor="1"> 1 </label>
              </div>
              <div className="col-lg">
                <input type="radio" id="2" name="question9" value="2"/><label htmlFor="2"> 2 </label>
              </div>
              <div className="col-lg">
                <input type="radio" id="3" name="question9" value="3"/><label htmlFor="3"> 3 </label>
              </div>
              <div className="col-lg">
                <input type="radio" id="4" name="question9" value="4"/><label htmlFor="4"> 4 </label>
              </div>
              <div className="col-lg">
                <input type="radio" id="5" name="question9" value="5"/><label htmlFor="5"> 5 </label>
              </div>
              <div className="col-lg">
                <input type="radio" id="6" name="question9" value="6"/><label htmlFor="6"> 6 </label>
              </div>
              <div className="col-lg">
                <input type="radio" id="7" name="question9" value="7"/><label htmlFor="7"> 7 </label>
              </div>
              <div className="col-lg">
                <input type="radio" id="8" name="question9" value="8"/><label htmlFor="8"> 8 </label>
              </div>
              <div className="col-lg">
                <input type="radio" id="9" name="question9" value="9"/><label htmlFor="9"> 9 </label>
              </div>
              <div className="col-lg">
                <input type="radio" id="10" name="question9" value="10"/><label htmlFor="10"> 10 </label>
              </div>
            </div>

            <label>10) Algum comentário ou sugestão que gostaria de fazer:</label>
            <div className="row row-cols-1 mb-5 align-items-center">
              <div className="col">
                <textarea className="w-100 inputPesquisa" rows="5"/>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-3">
                <button className="btn btn-buttonGreen3 w-100 text-white font-Spinnaker text-white">Confirmar</button>
              </div>
            </div>
          </form>
        </div> : ''}
      <div className={`container p-lg-5 pt-5 pb-5 pr-4 pl-4 ${pesquisa ? 'd-none' : 'd-block'}`}>
        <div className="row justify-content-between align-items-end">
          <div className="col-8">
            <h4 className="text-lwart-blue pb-2 mb-0 d-flex align-items-center"><b className="font-Spinnaker">Seu Centro de Coleta</b></h4>
          </div>
          <div className="col-4 justify-content-end d-flex">
            <img src={iconeCaminhao} alt="icone caminhao" className="pb-2"/>
          </div>
        </div>
        <div className="col-12 border-bottom-coleta mb-2" />
        <img src={pin} alt="Pin" className="float-left"/>
        <p className="ml-5">
          <b>Vitória da Conquista</b><br/>
        (10) 009001-0183 - Rua Antonio Roberto Paulo, 1310 - Alto de Balori - Vitória da Conquista - BA
        </p>
        <div className="col-12 pr-0 pl-0">
          <img src={placeholdermapa} className="w-100" />
        </div>
      </div>
      <div className={`container pr--lg5 pl-lg-5 pt-2 pb-2 pr-4 pl-4 ${pesquisa ? 'd-none' : 'd-block'}`}>
        <div className="row justify-content-between align-items-end">
          <div className="col-8">
            <h4 className="text-lwart-blue pb-2 mb-0 d-flex align-items-center"><b className="font-Spinnaker">Suas Licenças</b></h4>
          </div>
          <div className="col-4 justify-content-end d-flex">
            <img src={iconeCaminhao} alt="icone caminhao" className="pb-2"/>
          </div>
        </div>
        <p>Confira abaixo as licenças e documentos de que você precisa</p>
        <div className="col-12 border-bottom-coleta mb-0" />
        <div className="w-100 bg-lwart-blue pt-2 pb-2">
          <div className="row justify-content-between">
            <div className="col-lg-9 col-12 d-flex align-items-center">
              <p className="font-size-1 mb-0 text-white letter-space-normal text-uppercase pl-2"><b>Licenças Ambientais da Base (Transporte e Operação)</b></p>
            </div>
            {/* <div className="col-lg-3">
              <div className="d-flex justify-content-lg-end justify-content-center">
                <button className="btn btn-buttonWhite font-Spinnaker mr-2">Baixar Todas <img src={setabaixar} className="align-middle pl-2" /></button>
              </div>
            </div> */}
          </div>
        </div>
        <table className="w-100">
          <Molde titulo={<>Licença-01.pdf</>} />
          <Molde titulo={<>Licença-01.pdf</>} />
          <Molde titulo={<>Licença-01.pdf</>} />
          <Molde titulo={<>Licença-01.pdf</>} />
          <Molde titulo={<>Licença-01.pdf</>} />
          <Molde titulo={<>Licença-01.pdf</>} />
        </table>
        <div className="col-12 border-bottom-coleta mb-2 mt-5" />
        <p>Importante: Estas informações são confidenciais e destinadas a indivíduo(s)
          e propósitos específicos, sendo protegidas por lei. O sistema utiliza diversas fontes de consulta e as informações são
          atualizadas em diferentes momentos, de modo que as licenças poderá sofrer alteraçõesa qualquer momento e/ou apresentar
          alguma informação desatualizada. </p>
      </div>
    </>
  )
}

export default SoliciteSuaColeta

export const query = graphql`
  query {
    pagina:contentfulPagina(path: { eq: "/solicite-sua-coleta/" }) {
      path
      metaTitle
      metaDescription
      pageTitle
      tags
      ogImage {
        localFile {
          publicURL
        }
      }
    }
  }
`
